const browserStorageHelper = require('../helpers/browser-storage.helpers');
const global_variables = require('../../config');
const { GetTranslationsApi } = require('../services/api-services');

// call get translation api only once per json
let callGetTranslationApiController = false;

/**
 *
 * @param {string} originalValue
 * @returns if found translated string, else originalValue
 */
function checkTranslationInArrayNodes(originalValue) {
  const matchValue = Object.entries(global_variables.arr_nodes).find(
    ([key, value]) => originalValue === value.originalText && value.translatedText
  );

  return matchValue ? matchValue.translatedText : originalValue;
}

// split sentece_nodes
function splitSentences(str, separator) {
  const tempStr = str.split(separator);
  return tempStr;
}

/**
 *
 * @param {string} separator
 * @returns splited sentence_nodes object
 */
function splitSentenceNodes(separator) {
  try {
    const translationMap = {};
    for (let key in global_variables.sentence_nodes) {
      const entry = global_variables.sentence_nodes[key];

      if (entry.original_text && entry.translated_text) {
        // Split both original_text and translated_text based on the separator
        const originalParts = splitSentences(entry.original_text, separator);
        const translatedParts = splitSentences(entry.translated_text, separator);

        originalParts.forEach((part, index) => {
          if (translatedParts[index]) {
            let temp = part.trim();
            translationMap[temp] = translatedParts[index].trim();
          }
        });
      }
    }
    return translationMap;
  } catch (error) {
    return {};
  }
}

/**
 *
 * @param {string} originalValue
 * @returns if found translated string, else originalValue
 */
function checkTranslationInSentenceNodes(originalValue) {
  const sentenceObj = splitSentenceNodes(global_variables.sentence_merge_separator_receive);

  if (sentenceObj.hasOwnProperty(originalValue)) {
    return sentenceObj[originalValue];
  }
  return originalValue;
}

/**
 *
 * @param {string} originalValue
 * @returns if found - translated value, else originalValue
 */
async function checkTranslationInIndexDb(originalValue) {
  try {
    // check if indexDb is supported by browser
    if (window.indexedDB) {
      const translation = await browserStorageHelper.getParticularValueFromIndexDB(originalValue);
      if (translation) {
        return translation;
      } else {
        return originalValue;
      }
    } else {
      return originalValue;
    }
  } catch (error) {
    return originalValue;
  }
}

/**
 *
 * @param {string} originalValue
 * @returns if found translated value, else originalValue
 */
async function checkTranslationInGetTranslationApiRes(
  originalValue,
  callGetTranslationApi = false
) {
  try {
    if (callGetTranslationApi && callGetTranslationApiController) {
      await GetTranslationsApi(); // need to pass 'true' as a parameter if newly implemented indexDb is used/merged.
      callGetTranslationApiController = false;
    }
    const matchSentence =
      global_variables.translationData.sentences &&
      global_variables.translationData.sentences.find((obj) => obj.sentence === originalValue);
    if (matchSentence) {
      return matchSentence.translation;
    } else {
      return originalValue;
    }
  } catch (error) {
    return originalValue;
  }
}

/**
 *
 * @param {string} originalValue
 * @returns if found translated value, else originalValue
 */
async function translator(originalValue) {
  try {
    let translatedValue = '';

    if (originalValue === '') {
      return originalValue;
    } else {
      // step: 1 - check in global_variables.arr_nodes
      // translatedValue = await checkTranslationInArrayNodes(originalValue);

      // // check if translated string is NOT same, assuming translation is found in 'global_variables.arr_nodes'
      // if (originalValue !== translatedValue) {
      //   return translatedValue;
      // }

      // // check if translated string is same, assuming no translation found in step: 1
      // if (originalValue === translatedValue)
      // {
      //   // step: 2 - check in indexDb
      //   translatedValue = await checkTranslationInIndexDb(originalValue);
      // }

      // // check if translated string is NOT same, assuming translation is found in indexDb
      // if (originalValue !== translatedValue) {
      //   return translatedValue;
      // }

      // check if translated string is same, assuming no translation found in step: 2
      // if (originalValue === translatedValue)
      {
        // step: 3 - check in global_variables.translationData.sentences
        translatedValue = await checkTranslationInGetTranslationApiRes(originalValue);
      }

      // check if translated string is NOT same, assuming translation is found in 'global_variables.translationData.sentences'
      if (originalValue !== translatedValue) {
        return translatedValue;
      }

      // check if translated string is same, assuming no translation found in step: 3
      if (originalValue === translatedValue) {
        // step: 4 - check in 'global_variables.sentence_nodes'
        translatedValue = await checkTranslationInSentenceNodes(originalValue);
      }

      // check if translated string is NOT same, assuming translation is found in 'global_variables.sentence_nodes'
      if (originalValue !== translatedValue) {
        return translatedValue;
      }

      // check if translated string is same, assuming no translation found in step: 4
      if (originalValue === translatedValue) {
        // step: 5 - call get translations api and check in 'global_variables.translationData.sentences'
        translatedValue = await checkTranslationInGetTranslationApiRes(originalValue, true);
        callGetTranslationApiController = false;

        if (translatedValue) {
          return translatedValue;
        } else {
          return originalValue;
        }
      }
      return originalValue;
    }
  } catch (error) {
    return originalValue;
  }
}

module.exports = {
  // main function to parse the JSON and translate values
  async translateJson(inputJson) {
    try {
      if (inputJson === null || inputJson === undefined) {
        console.error('Invalid input JSON');
        return inputJson;
      }

      // call get translation api only once per json
      callGetTranslationApiController = true;

      for (const key in inputJson) {
        const jsonValue = inputJson[key];

        // check if JSON value is string
        if (typeof jsonValue === 'string') {
          const translatedValue = await translator(jsonValue);
          inputJson[key] = translatedValue;

          // check if JSON value is array
        } else if (Array.isArray(jsonValue)) {
          const translatedValues = await Promise.all(jsonValue.map((item) => translator(item)));
          inputJson[key] = translatedValues;

          // check if JSON value is object
        } else if (typeof jsonValue === 'object') {
          const translatedObject = {};
          for (const key in jsonValue) {
            if (jsonValue.hasOwnProperty(key)) {
              const currentValue = jsonValue[key];
              translatedObject[key] = await translator(currentValue);
            }
          }
          inputJson[key] = translatedObject;
        }
      }
      return inputJson;
    } catch (error) {
      console.log('Error: no translation found.', error);
      return inputJson;
    }
  }
};
