const global_variables = require('../../config');

// open database and return db instance
async function openDatabase() {
  try {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(global_variables.translatoinsIndexDbStorage, 1);

      request.onupgradeneeded = function (event) {
        const db = event.target.result;

        /**
         * first check if we have language code array, create store for each user subscribed language
         * excluding English ('en')
         */
        if (
          global_variables.lang &&
          Array.isArray(global_variables.lang) &&
          global_variables.lang.length
        ) {
          global_variables.lang.forEach((langCode) => {
            // check if source language, to ensure no objecStore is created for source language code
            if (langCode.code !== global_variables.source_lang_code) {
              if (!db.objectStoreNames.contains(langCode.code)) {
                db.createObjectStore(langCode.code, { autoIncrement: true });
              }
            }
          });
        }
      };

      request.onsuccess = function (event) {
        // Return database instance when it's successfully opened
        resolve(event.target.result);
      };

      request.onerror = function (event) {
        reject('Error opening database: ' + event.target.error);
      };
    });
  } catch (error) {
    console.log('Error opening database');
  }
}

// Open or create IndexDb and store translations data
async function storeTranslationsData(translationsArray) {
  try {
    const database = await openDatabase();

    const storeTranslations = await new Promise((resolve, reject) => {
      if (
        global_variables.subdomainObjectStore &&
        database.objectStoreNames.contains(global_variables.subdomainObjectStore)
      ) {
        // clear object store transaction
        //   const transactionClear = database.transaction(
        //     global_variables.subdomainObjectStore,
        //     'readwrite'
        //   );
        //   const storeClear = transactionClear.objectStore(global_variables.subdomainObjectStore);

        //   const clearRequest = storeClear.clear();

        //   clearRequest.onsuccess = function () {
        // console.log('Object store cleared.');

        // Start transaction to write to object store
        const transaction = database.transaction(
          [global_variables.subdomainObjectStore],
          'readwrite'
        );
        const objectStore = transaction.objectStore(global_variables.subdomainObjectStore);

        for (const obj of translationsArray) {
          const storedData = objectStore.put(
            obj,
            global_variables.document_url_full + '-' + obj.xpath
          );
        }

        transaction.oncomplete = function () {
          resolve();
        };

        // Handle any errors that occur in the transaction
        transaction.onerror = function (event) {
          reject('Transaction error: ' + event.target.error);
        };
        //   };
        //   clearRequest.onerror = function () {
        //     console.error('Error clearing the store.');
        //   };
      }
    });
  } catch (error) {
    console.log('IndexDb error');
  }
}

// Retrieve data from indexDb of selected store(language code)
async function retrieveTranslationsDataFromIndexDb() {
  try {
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([global_variables.subdomainObjectStore], 'readonly');
      const objectStore = transaction.objectStore(global_variables.subdomainObjectStore);
      // const request = objectStore.getAll();
      // request.onsuccess = function (event) {
      //   resolve(request.result);
      // };
      // request.onerror = function (event) {
      //   reject('Error retrieving data: ' + event.target.error);
      // };

      const url = global_variables.document_url_full;
      // Open cursor to iterate through all records
      const cursorRequest = objectStore.openCursor();
      const results = [];

      cursorRequest.onsuccess = function (event) {
        const cursor = event.target.result;

        if (cursor) {
          // Get the key of the current record
          const key = cursor.key;

          // Check if the key contains the url
          if (key.includes(url)) {
            results.push(cursor.value);
          }

          // Continue iterating to next record
          cursor.continue();
        } else {
          // Finished iterating over all records
          resolve(results);
        }
      };

      cursorRequest.onerror = function (event) {
        console.error('Error fetching records:', event.target.error);
      };
    });
  } catch (error) {
    console.log('No data found in indexdDb');
  }
}

module.exports = {
  storeTranslationsData,
  retrieveTranslationsDataFromIndexDb
};
