require('./less/main.css');

const clients = require('./clients');
const features = require('./features');

const myGov = require('./clients/mygov');

// ================ DEPRECATED IMPORTS START HERE ================
const globalConfig = require('../config');
const { cleanString, isObjEmpty } = require('./helpers/utils');
const { identifyScriptMode, isLiveJs, isProxy } = require('./helpers/mode');
const { storeValuesInIndexDB } = require('./helpers/browser-storage.helpers');
const {
  access_token,
  isTextTranslatedText,
  isStatusDataAvailable
} = require('./helpers/app-helpers');
const { cleanExistingTranslationObj } = require('./services/translation-service');
const {
  GetTranslationsApi,
  callApiRequest,
  loginApi,
  subscriptionStatusApi,
  checkAuthenticationToken,
  checkIfTraversingCompleted,
  checkNodeType,
  domNodeInsertedHandler,
  startTraversingNow,
  translateAllFramesOnPage,
  traverseNodes,
  isNodeASentence,
  getObjectUrlsApi,
  pageViewsApi
} = require('./services/api-services');
const { translateJson } = require('../sdk/services/translate-json-service');
// ================ DEPRECATED IMPORTS END HERE ================

// Redclare global configurations for browser
globalConfig.url_object = new URL(document.URL);
globalConfig.web_owner = false;
if (
  globalConfig.url_object.searchParams.has('dota-token') &&
  globalConfig.url_object.searchParams.has('language_code')
) {
  globalConfig.document_url = globalConfig.url_object.origin;
  if (!globalConfig.document_url.endsWith('/')) {
    globalConfig.document_url = globalConfig.document_url + '/';
  }
  globalConfig.web_owner = true;
  globalConfig.dota_token = globalConfig.url_object.searchParams.get('dota-token');
  globalConfig.selected_lang_code = globalConfig.url_object.searchParams.get('language_code');
}

// // check if page lang code exist..
if (localStorage.getItem('devnagri_source_lang_code')) {
  globalConfig.source_lang_code = localStorage.getItem('devnagri_source_lang_code');
} else {
  globalConfig.source_lang_code = 'en';
  localStorage.setItem('devnagri_source_lang_code', globalConfig.source_lang_code);
}
//check if default lang code exist..
if (localStorage.getItem('devnagri_default_lang_code')) {
  globalConfig.default_lang_code = localStorage.getItem('devnagri_default_lang_code');
}

globalConfig.document_url_full = document.URL;
globalConfig.metatag_arr = {
  TITLE: {
    tag: 'TITLE',
    engText: document.title,
    translatedText: document.title
  }
};

globalConfig.dota_token = new URL(document.URL).searchParams.get('dota-token');
globalConfig.expiry_token_time = localStorage.getItem('devnagri_token_expiry_time');

async function initializeTranslation(config) {
  // Identify script mode as proxy or livejs
  // This will be important later to various decisions.
  identifyScriptMode(config);

  const disableLiveJSURLs = globalConfig.disable_livejs_urls
    ? globalConfig.disable_livejs_urls.split(',')
    : [];

  if (
    !isProxy() &&
    disableLiveJSURLs.includes(globalConfig.url_object.origin) &&
    (!globalConfig.url_object.searchParams.has('dota-token') ||
      !globalConfig.url_object.searchParams.has('language_code'))
  )
    return;

  window.addEventListener('scroll', async (event) => {
    //console.log('Page is being scrolled');
    if (isLiveJs() && globalConfig.subscription_status_active) {
      //console.log('Page is being scrolled LiveJS');
      startTraversingNow();
      await checkIfTraversingCompleted();
    }
  });

  /**
   * Loop through clients to identify and intialize the incoming
   * request to trigger, enhance or make updates.
   *
   * We can possibly add await in this for loop to run
   * all client level code synchronously.
   */
  for (const client of clients) {
    // Check if client has a initialization function.
    if ('initialize' in client && typeof client.initialize === 'function') {
      await client.initialize(config, globalConfig.url_object);
    }
  }

  /**
   * Loop through features to intialize various triggers,
   * enhancement and updates.
   *
   * We can possibly add await in this for loop to run
   * all features synchronously.
   */
  for (const feature of features) {
    // Check if feature has a initialization function.
    if ('initialize' in feature && typeof feature.initialize === 'function') {
      await feature.initialize(config);
    }
  }

  // ================ OLD CODE STARTS HERE ================

  // for live js mode default lang code will be set in login api
  if (isProxy()) {
    if (!config.default_lang_code) {
      config.default_lang_code = 'en';
    }
    globalConfig.default_lang_code = config.default_lang_code;
    globalConfig.selected_lang_code = config.default_lang_code;
  } else if (isLiveJs() && !globalConfig.web_owner) {
    globalConfig.selected_lang_code = config.default_lang_code;
  }

  if (!config.ui_mode) {
    config.ui_mode = 'custom';
  }

  if (!config.apiKey) {
    return;
  }

  // consider ui mode as native in case of context editor.
  if (globalConfig.web_owner) {
    config.ui_mode = 'native';
  }

  if (config.ui_mode) {
    globalConfig.should_render_ui = config.ui_mode;
  }

  if (config.token && config.expires_in) {
    globalConfig._access_token = config.token;
    // globalConfig.expiry_token_time = config.expires_in;
  }

  /**
   * commenting this to test if it requires here to call login api, as we already calling login and status apis
   * below in 'checkAuthenticationToken' function.
   * We will remove it after testing.
   */
  // if (!access_token()) {
  //   await loginApi(globalConfig.login_api_url, config.apiKey);
  // }

  // check in 'config' if status data is available, set it on sdk 'globalConfig' object
  if (config.status_api_response_data && !isObjEmpty(config.status_api_response_data)) {
    globalConfig.status_api_global_data = config.status_api_response_data;
  }

  globalConfig.api_key = config.apiKey;
  globalConfig.document_url = config.domain;

  globalConfig.document_url_full = isProxy()
    ? document.URL.replaceAll(config.requested_domain, config.domain)
    : document.URL;

  globalConfig.subdomainObjectStore = isProxy()
    ? globalConfig.default_lang_code
    : config.target_lang_code;

  if (isLiveJs() || isProxy()) {
    if (isProxy()) {
      globalConfig.web_owner = false;
    }

    // used selected lang code for because in upper portion of the code we already picked code from query param and assigned it to selected_lang_code
    globalConfig.default_lang_code = globalConfig.web_owner
      ? globalConfig.selected_lang_code
      : globalConfig.default_lang_code;

    //after authenticating(login and status api calls) it calls renderTranslateUI and getTranslations api
    await checkAuthenticationToken(globalConfig.expiry_token_time, config.apiKey);

    if (globalConfig.selected_lang_code === globalConfig.source_lang_code) {
      globalConfig.selected_lang_code = globalConfig.default_lang_code;
    }

    if (globalConfig.subscription_status_active) {
      startTraversingNow();
      await checkIfTraversingCompleted();
      // set text direction rtl for urdu
      const rtlLangs = ['ur', 'ar'];
      if (rtlLangs.includes(globalConfig.selected_lang_code)) {
        document.body.setAttribute('dir', 'rtl');
      } else {
        document.body.setAttribute('dir', 'ltr');
      }

      // set lang attribute on html tag
      document.documentElement.setAttribute('lang', globalConfig.selected_lang_code);
    }
  }

  const enableDynamicTranslation = !config.enable_static_content;

  // globalConfig.enable_object_mapping = config.enable_object_mapping;

  globalConfig.translation_api_url = `${globalConfig.api_baseurl}/translations?url=${globalConfig.document_url_full}`;
  globalConfig.update_api_url = `${globalConfig.api_baseurl}/translations`;

  globalConfig.target_lang_code = config.target_lang_code;

  globalConfig.api_key = config.apiKey;
  globalConfig.serialCount = 0;

  // globalConfig.translation_api_global_data = isProxy()
  //   ? cleanExistingTranslationObj(config.existingTranslations)
  //   : {};

  if (enableDynamicTranslation) {
    if (globalConfig.translation_api_global_data) {
      if (
        globalConfig.status_api_global_data &&
        !globalConfig.status_api_global_data.is_demo &&
        globalConfig.enable_index_db &&
        globalConfig.subdomainObjectStore
      ) {
        storeValuesInIndexDB(globalConfig.translation_api_global_data);
      }
    }
  }

  if (isProxy()) {
    globalConfig.domain = config.domain;
    globalConfig.requested_domain = config.requested_domain;
    globalConfig.subdomain_object_urls_hash_map = config.subdomain_object_urls_hash_map;
  }

  globalConfig.config = config;

  globalConfig.frame_ids.forEach(
    (frameId) => (globalConfig.frames_translation_status_map[frameId] = true)
  );

  if (enableDynamicTranslation) {
    if (isProxy()) {
      // document.addEventListener('DOMCharacterDataModified', (event) => {
      //   if (!globalConfig.edit_mode_enabled) {
      //     if (globalConfig.source_lang_code === globalConfig.selected_lang_code) {
      //       return;
      //     }
      //     if (globalConfig.source_lang_code !== globalConfig.selected_lang_code) {
      //       const cleanValue = cleanString(event.target.textContent);
      //       if (cleanValue && !isTextTranslatedText(cleanValue)) {
      //         if (globalConfig.enable_blacklist) {
      //           const { parentElement } = event.target;
      //           // node blacklist by class condition
      //           if (
      //             globalConfig.blacklist_classes
      //               .map(
      //                 (item) =>
      //                   parentElement &&
      //                   parentElement.getAttribute('class') &&
      //                   parentElement.getAttribute('class').includes(item)
      //               )
      //               .some(Boolean)
      //           ) {
      //             return;
      //           }

      //           // node blacklist by id condition
      //           if (
      //             parentElement &&
      //             parentElement.getAttribute &&
      //             parentElement.getAttribute('id') &&
      //             globalConfig.blacklist_ids.includes(parentElement.getAttribute('id'))
      //           ) {
      //             return;
      //           }
      //         }

      //         const node = event.target;
      //         if (
      //           globalConfig.enable_sentence_merging &&
      //           node &&
      //           node.parentElement &&
      //           isNodeASentence(node.parentElement)
      //         ) {
      //           traverseNodes(node.parentElement, checkNodeType);
      //         } else {
      //           traverseNodes(event.target, checkNodeType);
      //         }
      //       }
      //     }
      //   }
      // });

      const targetNode = document.body; // You can change this to any specific target node

      const observerConfig = {
        characterData: true, // Observe changes to character data
        subtree: true // Observe all descendants of the target node
      };

      const callback = (mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'characterData') {
            const event = { target: mutation.target }; // Create an event-like object

            if (!globalConfig.edit_mode_enabled) {
              if (globalConfig.source_lang_code === globalConfig.selected_lang_code) {
                return;
              }
              if (globalConfig.source_lang_code !== globalConfig.selected_lang_code) {
                const cleanValue = cleanString(event.target.textContent);
                if (cleanValue && !isTextTranslatedText(cleanValue)) {
                  if (globalConfig.enable_blacklist) {
                    const { parentElement } = event.target;
                    // node blacklist by class condition
                    if (
                      globalConfig.blacklist_classes
                        .map(
                          (item) =>
                            parentElement &&
                            parentElement.getAttribute('class') &&
                            parentElement.getAttribute('class').includes(item)
                        )
                        .some(Boolean)
                    ) {
                      return;
                    }

                    // node blacklist by id condition
                    if (
                      parentElement &&
                      parentElement.getAttribute &&
                      parentElement.getAttribute('id') &&
                      globalConfig.blacklist_ids.includes(parentElement.getAttribute('id'))
                    ) {
                      return;
                    }
                  }

                  const node = event.target;
                  if (
                    globalConfig.enable_sentence_merging &&
                    node &&
                    node.parentElement &&
                    isNodeASentence(node.parentElement)
                  ) {
                    traverseNodes(node.parentElement, checkNodeType);
                  } else {
                    traverseNodes(event.target, checkNodeType);
                  }
                }
              }
            }
          }
        }
      };

      const observer = new MutationObserver(callback);
      observer.observe(targetNode, observerConfig);
    }

    // for getting notify dynamic rendering
    // document.addEventListener('DOMNodeInserted', (e) => {
    //   if (!globalConfig.edit_mode_enabled) {
    //     if (globalConfig.source_lang_code === globalConfig.selected_lang_code) {
    //       return;
    //     }
    //     if (Object.values(globalConfig.frames_translation_status_map).some((val) => val)) {
    //       translateAllFramesOnPage();
    //     }
    //     domNodeInsertedHandler(e);
    //   }

    //   if (myGov.isEnabled()) {
    //     myGov.fixForLinksMissMatchWithDynamicContent();
    //   }
    // });

    const targetNode = document.body; // You can change this to any specific target node

    const observerConfig = {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true
    };

    const callback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          for (const node of mutation.addedNodes) {
            // if (node.nodeType === Node.ELEMENT_NODE)
            {
              const e = { target: node }; // Create an event-like object

              if (!globalConfig.edit_mode_enabled) {
                if (globalConfig.source_lang_code === globalConfig.selected_lang_code) {
                  return;
                }
                if (Object.values(globalConfig.frames_translation_status_map).some((val) => val)) {
                  translateAllFramesOnPage();
                }
                domNodeInsertedHandler(e);
              }

              if (myGov.isEnabled()) {
                myGov.fixForLinksMissMatchWithDynamicContent();
              }
            }
          }
        }
      }
    };

    const observer = new MutationObserver(callback);

    observer.observe(targetNode, observerConfig);

    GetTranslationsApi();
  }

  if (isProxy()) {
    if (enableDynamicTranslation) {
      // checks status data is available, if not call status api
      if (!isStatusDataAvailable()) {
        await callApiRequest(() => subscriptionStatusApi(globalConfig.status_api_url));
      }
    }
  }

  if (
    globalConfig.status_api_global_data &&
    globalConfig.status_api_global_data.enable_object_mapping
  ) {
    globalConfig.enable_object_mapping = true;
    await getObjectUrlsApi();
  }

  if (
    !globalConfig.page_view_api_count &&
    globalConfig.selected_lang_code != globalConfig.status_api_global_data.source_lang_code
  ) {
    Promise.all([pageViewsApi(globalConfig.page_view_api_url)]);
  }
  ++globalConfig.page_view_api_count;
  // ================ OLD CODE ENDS HERE ================
}

/**
//  * Initialize DOTA Web translations.
 *
 * @param {object} config User/Proxy provided configuration
 * @returns void
 */
async function initTranslation(config) {
  document.onreadystatechange = async () => {
    if (document.readyState === 'complete') {
      await initializeTranslation(config);
    }
  };
}

const Devnagri = {
  initTranslation,
  initializeTranslation,
  translateJson
};

window.Devnagri = Devnagri;
